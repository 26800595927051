<template>
  <b-nav-item-dropdown
    right
    class="profile-dropdown"
    toggle-class="nav-user mr-0 toggle"
  >
    <template #button-content>
      <div class="media user-profile ">
        <b-avatar variant="light"></b-avatar>
        <div class="media-body text-left d-none d-sm-block">
          <h6 class="pro-user-name ml-2 my-0">
            <span>{{ userInfo? userInfo.username || userInfo.email: 'username' }}</span>
            <span class="pro-user-desc text-muted d-block mt-1">
              {{ adminRole }}
            </span>
          </h6>
        </div>
        <feather
          type="chevron-down"
          class="text-small ml-sm-2 align-self-center"
        ></feather>
      </div>
    </template>

    <b-dropdown-header class="d-sm-none">
      <small>[{{ adminRole }}]</small>
      {{ userInfo? userInfo.username || userInfo.email: 'username' }}
    </b-dropdown-header>
    <!-- <b-dropdown-item
      href="/account"
      :active="!!(currentRouteName === 'account')"
    >
      <feather
        type="user"
        class="icon-xs"
      ></feather>
      <span class="pl-1">
        {{$allowPermission('agent:edit.profile')? $t(`routes.PROFILE`): 'ข้อมูลโปรไฟล์'}}
      </span>
    </b-dropdown-item> -->
    <b-dropdown-item v-b-modal.register-link>
      <feather
        type="link"
        class="icon-xs"
      ></feather>
      <span class="pl-1">
        {{ $t(`agent.register_link`)}}
      </span>
    </b-dropdown-item>
    <b-dropdown-item @click="onChangeLang">
      <feather
        type="globe"
        class="icon-xs"
      ></feather>
      <span class="pl-1">
        {{ $i18n.locale === 'en'? $t('utils.lang.th', 'th'): $t('utils.lang.en', 'en')}}
      </span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isMenuEnabled()"
      href="/agent-setting"
      :active="!!(currentRouteName === 'AGENT_SETTING')"
    >
      <feather
        type="sliders"
        class="icon-xs"
      ></feather>
      <span class="pl-1">
        ตั้งค่าเอเย่นต์
      </span>
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item
      class="notify-item p-0"
      @click="onLogout"
    >
      <feather
        type="log-out"
        class="icon-dual-warning icon-xs"
      />
      <span class="pl-1 text-warning">{{ $t('buttons.logout')}}</span>
    </b-dropdown-item>
    <b-modal
      id="register-link"
      :title="$t(`agent.register_link`)"
      ok-only
    >
      <b-form-group label="URL">
        <b-input-group>
          <b-form-input
            :value="registerUrl"
            readonly
          ></b-form-input>
          <template #append>
            <b-button
              v-clipboard:copy="registerUrl"
              v-clipboard:success="onCopy"
              type="button"
            >
              <i class="uil uil-file-copy-alt"></i>
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </b-modal>
  </b-nav-item-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['userInfo', 'adminRole']),
    currentRouteName() {
      return this.$route.name
    },
    registerUrl() {
      return `${
        this.userInfo.registration_url || process.env.VUE_APP_CLIENT_URL
      }/register?agent_id=${this.userInfo.agentId || ''}`
    },
  },
  methods: {
    ...mapActions(['logout']),
    isMenuEnabled() {
      if (
        this.$allowPermission('agent:contact.setting') ||
        this.$allowPermission('agent:line-login.setting') ||
        this.$allowPermission('agent:withdraw.setting') ||
        this.$allowPermission('agent:cashback.setting') ||
        this.$allowPermission('agent:af.setting') ||
        this.$allowPermission('agent:edit.profile')
      ) {
        return true
      }
      return false
    },
    onLogout() {
      this.logout()
    },
    onChangeLang() {
      if (this.$i18n.locale === 'en') {
        this.$i18n.locale = 'th'
      } else if (this.$i18n.locale === 'th') {
        this.$i18n.locale = 'en'
      }
    },
    onCopy() {
      this.$bvToast.toast(`${this.$t('buttons.copied')}`, {
        variant: 'success',
        title: 'Success',
      })
    },
  },
}
</script>